import React from 'react';
import {connect} from 'react-redux';
import {Modal, Button, Card, CardBody, CardFooter} from 'reactstrap';
import {StudentButtonList} from '..';
import {
  keepOpenRule,
  removeOpenRule,
  toggleStudentForOpenRule,
} from '../../redux/actions/rule';
import {
  openRuleSelector,
  studentListSelector,
} from '../../redux/selectors';

export const RuleModal = ({
  shouldGroup,
  studentList,
  keepOpenRule,
  removeOpenRule,
  toggleStudentForOpenRule,
}) =>
  <Modal isOpen centered size='sm'>
    <Card id='OpenRule'>
      <CardBody>
        <StudentButtonList
          header={`Select students to ${shouldGroup ? 'keep together' : 'keep separate'}`}
          studentList={studentList}
          toggleStudentActive={id => toggleStudentForOpenRule(id)}
        />
      </CardBody>
      <CardFooter>
        <Button
          color='primary'
          onClick={keepOpenRule}
        >
          Keep Rule
        </Button>
        <Button
          color='primary'
          onClick={removeOpenRule}
        >
          Remove Rule
        </Button>
      </CardFooter>
    </Card>
  </Modal>
;

const stateToProps = state => {
  const openRule = openRuleSelector(state);
  const studentList = studentListSelector(state).map(student => ({
    ...student,
    isActive: !openRule.studentList.some(openRuleStudent => openRuleStudent.id === student.id),
  }));

  return {
    shouldGroup: openRule.shouldGroup,
    studentList,
  };
};

const dispatchToProps = {
  keepOpenRule,
  removeOpenRule,
  toggleStudentForOpenRule,
};

export default connect(stateToProps, dispatchToProps)(RuleModal);
