import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Modal, ModalBody} from 'reactstrap';
import firebase from 'firebase/compat/app';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import {ConfirmationDialog} from '..';
import {handleLogIn, handleLogOut} from '../../redux/actions/auth';
import analytics from '../../utils/analytics';
import 'firebase/compat/auth';
import * as firebaseui from 'firebaseui';

export const AuthModal = ({
  handleLogIn,
  handleLogOut,
  isOpen,
  setIsOpen,
}) => {
  const [isVerifyDialogOpen, setIsVerifyDialogOpen] = useState(false);
  const firebaseAuth = firebase.auth();

  const handleClose = () => {
    setIsVerifyDialogOpen(false);
    setIsOpen(false);
  };

  return (
    <>
      <Modal id='AuthModal' centered isOpen={isOpen} toggle={handleClose}>
        <ModalBody>
          <StyledFirebaseAuth
            uiConfig={{
              signInOptions: [{
                provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                requireDisplayName: false,
              }],
              callbacks: {
                signInSuccessWithAuthResult: authResult => {
                  if (authResult.user.emailVerified) {
                    handleLogIn();
                    setIsOpen(false);
                  }
                  else {
                    analytics.account.signUp();
                    handleLogOut();
                    firebase.auth().currentUser.sendEmailVerification({
                      // URL to link to from email verification landing page
                      url: window.location.href,
                    }).catch(console.error);
                    setIsVerifyDialogOpen(true);
                  }
                  return false; // Returning false prevents automatic redirect
                },
              },
              credentialHelper: firebaseui.auth.CredentialHelper.NONE,
            }}
            firebaseAuth={firebaseAuth}
          />
        </ModalBody>
      </Modal>
      <ConfirmationDialog
        isOpen={isVerifyDialogOpen}
        setIsOpen={setIsVerifyDialogOpen}
        headerText='One more step!'
        confirmText='Got it'
        handleClose={handleClose}
      >
        <ModalBody>
          <p>
            You will receive an email shortly with a link to finish sign-up.
            <br />
            After that the whole power of Charmed will be yours!
          </p>
        </ModalBody>
      </ConfirmationDialog>
    </>
  );
};

const dispatchToProps = {handleLogIn, handleLogOut};

export default connect(null, dispatchToProps)(AuthModal);
