import React from 'react';
import {connect} from 'react-redux';
import {withRouter, Switch, Route, Redirect} from 'react-router-dom';
import {Container} from 'reactstrap';
import {GeneratePage, RulesPage, UnsupportedPage, WelcomePage} from './pages';
import {ClassModal, RuleModal, LoadingMask, Navbar, OpenClassNav, AssignmentModal} from './components';
import {
  isOpenClassLoadingSelector,
  isClassModalVisibleSelector,
  isRuleModalVisibleSelector,
  hasOpenClassSelector,
  isAssignmentModalVisibleSelector,
} from './redux/selectors';

function App({
  isOpenClassLoading,
  isClassModalVisible,
  isRuleModalVisible,
  isAssignmentModalVisible,
  hasOpenClass,
}) {
  return (
    <React.Fragment>
      <header>
        <Navbar />
      </header>
      <main>
        {isOpenClassLoading && <LoadingMask />}
        {isClassModalVisible && <ClassModal />}
        {isRuleModalVisible && <RuleModal />}
        {isAssignmentModalVisible && <AssignmentModal />}
        <Switch>
          <Route exact path='/' component={WelcomePage} />
          <Route path='/unsupported' component={UnsupportedPage} />
          <Route>
            <>
              {!hasOpenClass && <Redirect to='/' />}
              <OpenClassNav />
              <Container>
                <Route path='/generate' component={GeneratePage} />
                <Route path='/rules' component={RulesPage} />
              </Container>
            </>
          </Route>
        </Switch>
      </main>
    </React.Fragment>
  );
}

const stateToProps = state => ({
  isOpenClassLoading: isOpenClassLoadingSelector(state),
  isClassModalVisible: isClassModalVisibleSelector(state),
  isRuleModalVisible: isRuleModalVisibleSelector(state),
  isAssignmentModalVisible: isAssignmentModalVisibleSelector(state),
  hasOpenClass: hasOpenClassSelector(state),
});

export default withRouter(connect(stateToProps)(App));
