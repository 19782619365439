import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Button} from 'reactstrap';
import {AuthModal} from '..';
import {handleLogOut} from '../../redux/actions/auth';
import {isLoggedInSelector} from '../../redux/selectors';

export const AccountButton = ({isLoggedIn, handleLogOut}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const buttonHandler = isLoggedIn ? handleLogOut : () => setIsModalOpen(true);
  const buttonText = isLoggedIn ? 'Log Out' : 'Log In or Create Account';

  return (
    <>
      <Button color='success' className='bold' onClick={buttonHandler}>{buttonText}</Button>
      <AuthModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      />
    </>
  );
};

const stateToProps = state => ({
  isLoggedIn: isLoggedInSelector(state),
});

const dispatchToProps = {handleLogOut};

export default connect(stateToProps, dispatchToProps)(AccountButton);
