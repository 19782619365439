import {push} from 'connected-react-router';
import {NORMAL, ERROR} from '../../utils/constants';
import {fetchClassList} from './classList';
import model from '../reducers/model';
import firebase from 'firebase/compat/app';
import analytics from '../../utils/analytics';

const {STATUS, CLASS_LIST, STUDENT_LIST, RULE_LIST, OPEN_CLASS, IS_LOGGED_IN} = model;

const clearStore = () => dispatch => {
  dispatch(STATUS.SET, NORMAL);
  dispatch(CLASS_LIST.CLEAR);
  dispatch(STUDENT_LIST.CLEAR);
  dispatch(RULE_LIST.CLEAR);
  dispatch(OPEN_CLASS.ID.UNSET);
  dispatch(OPEN_CLASS.NAME.UNSET);
};

export const handleLogIn = () => async dispatch => {
  try {
    dispatch(IS_LOGGED_IN.SET, true);
    dispatch(clearStore());
    dispatch(push('/'));
    analytics.account.logIn();
    await dispatch(fetchClassList());
  }
  catch (error) {
    analytics.account.error('handleLogIn');
    dispatch(STATUS.SET, ERROR);
  }
};

// LOG_OUT, CLOSE_CLASS, CLEAR_RULES, CLEAR_CLASS, CLEAR_STUDENTS
export const handleLogOut = () => async dispatch => {
  dispatch(IS_LOGGED_IN.SET, false);
  dispatch(clearStore());
  dispatch(push('/'));
  firebase.auth().signOut();
};
