
import _uniq from 'lodash/uniq';
import {MAX_STUDENTS_PER_CLASS} from '../../utils/constants';

export const getNameValidation = name => !name && 'Name is required.';
export const getStudentsValidation = studentsText => {
  const studentNames = studentsText.split('\n').filter(Boolean);

  if (studentNames.length === 0) {
    return 'Students are required.';
  }
  if (studentNames.length === 1) {
    return 'Must have more than 1 student.';
  }
  if (studentNames.length > MAX_STUDENTS_PER_CLASS) {
    return `${MAX_STUDENTS_PER_CLASS} students maximum. (You have ${studentNames.length})`;
  }
  if (_uniq(studentNames).length !== studentNames.length) {
    return 'Cannot have duplicate student names.';
  }
  return null;
};
