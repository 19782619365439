import React from 'react';
import _range from 'lodash/range';
import Student from './Student';
import {getGridDimensions, CELL_SIZE, GRID_GAP} from './config';

// Try to get it as close to square as possible
export const Group = ({maxWidth, groupSize}) => {
  const {rows, cols} = getGridDimensions(maxWidth, groupSize);
  const students = _range(0, groupSize).map(index =>
    <Student key={index} cols={cols} index={index} />
  );

  return (
    <div
      className='group-sizes-preview-group'
      style={{
        width: `${cols * CELL_SIZE + GRID_GAP * 2}px`,
        height: `${rows * CELL_SIZE + GRID_GAP * 2}px`,
        gridTemplateRows: `repeat(${rows})`,
        gridTemplateColumns: `repeat(${cols})`,
      }}
    >
      {students}
    </div>
  );
};

export default Group;
