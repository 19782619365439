import {createStore, applyMiddleware, compose} from 'redux';
import {routerMiddleware} from 'connected-react-router';
import thunk from 'redux-thunk';
import rootReducer, {history} from './reducers';
import {modelMiddleware} from 'redux-reducer-patterns';

let composeEnhancers = compose;

if (!/https:\/\/charmed\.cloud/.test(window?.location?.origin)) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line
}

export default createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      modelMiddleware,
      routerMiddleware(history),
      thunk
    )
  )
);
