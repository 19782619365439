import React from 'react';
import {Spinner} from 'reactstrap';

export const LoadingMask = () =>
  <div id='LoadingMask'>
    <div className='absolute-center'>
      <Spinner
        style={{
          width: '4rem',
          height: '4rem',
          border: '0.5em solid currentColor',
          borderRightColor: 'transparent',
        }}
      />
    </div>
  </div>
;

export default LoadingMask;
