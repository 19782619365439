import React from 'react';
import {connect} from 'react-redux';
import {StudentButtonList} from '../../../components';
import {setIsClassModalVisible} from '../../../redux/actions/class';
import {toggleStudentAbsent} from '../../../redux/actions/studentList';
import {studentListSelector} from '../../../redux/selectors';

class StudentsPanel extends React.Component {
  render() {
    const {studentList, toggleStudentAbsent} = this.props;

    return (
      <div className='skinny-panel'>
        <StudentButtonList
          header='Mark any students absent'
          studentList={studentList.map(student => ({
            ...student,
            isActive: student.isPresent,
          }))}
          toggleStudentActive={id => toggleStudentAbsent(id)}
        />
      </div>
    );
  }
}

const stateToProps = state => ({
  studentList: studentListSelector(state),
});

const dispatchToProps = {setIsClassModalVisible, toggleStudentAbsent};

export default connect(stateToProps, dispatchToProps)(StudentsPanel);
