/* eslint-disable new-cap */
import {modelDataTypes, configureModel} from 'redux-reducer-patterns';
import {NORMAL, TARGETING_MODE} from '../../utils/constants';

const {VALUE, ARRAY} = modelDataTypes;

const init = {
  isLoggedIn: false,
  status: NORMAL,
  openClass: {
    id: undefined,
    name: undefined,
    isLoading: false,
    isModalVisible: false,
    isModalEditMode: false,
  },
  openRule: {
    id: undefined,
    shouldGroup: true,
    studentList: [],
    isModalVisible: false,
    isModalEditMode: false,
  },
  openAssignment: {
    id: undefined,
    name: undefined,
    isModalVisible: false,
    isSaved: false,
    targetingMode: TARGETING_MODE.STUDENTS_PER_GROUP,
    targetGroupSize: 2,
    targetGroupCount: 2,
    groups: [],
  },
  classList: [],
  isClassListVisible: false,
  isClassListLoading: false,
  studentList: [],
  ruleList: [],
  assignmentList: [],
};

const model = {
  IS_LOGGED_IN: VALUE(init.isLoggedIn),
  STATUS: VALUE(init.status),
  OPEN_CLASS: {
    ID: VALUE(init.openClass.id),
    NAME: VALUE(init.openClass.name),
    IS_LOADING: VALUE(init.openClass.isLoading),
    IS_MODAL_VISIBLE: VALUE(init.openClass.isModalVisible),
    IS_MODAL_EDIT_MODE: VALUE(init.openClass.isModalEditMode),
  },
  OPEN_RULE: {
    ID: VALUE(init.openRule.id),
    SHOULD_GROUP: VALUE(init.openRule.shouldGroup),
    STUDENT_LIST: ARRAY(init.openRule.studentList),
    IS_MODAL_VISIBLE: VALUE(init.openRule.isModalVisible),
    IS_MODAL_EDIT_MODE: VALUE(init.openRule.isModalEditMode),
  },
  OPEN_ASSIGNMENT: {
    ID: VALUE(init.openAssignment.id),
    NAME: VALUE(init.openAssignment.name),
    IS_MODAL_VISIBLE: VALUE(init.openAssignment.isModalVisible),
    IS_SAVED: VALUE(init.openAssignment.isSaved),
    TARGETING_MODE: VALUE(init.openAssignment.targetingMode),
    TARGET_GROUP_SIZE: VALUE(init.openAssignment.targetGroupSize),
    TARGET_GROUP_COUNT: VALUE(init.openAssignment.targetGroupCount),
    GROUPS: VALUE(init.openAssignment.groups),
  },
  CLASS_LIST: ARRAY(init.classList),
  IS_CLASS_LIST_VISIBLE: VALUE(init.isClassListVisible),
  IS_CLASS_LIST_LOADING: VALUE(init.isClassListLoading),
  STUDENT_LIST: ARRAY(init.studentList),
  RULE_LIST: ARRAY(init.ruleList),
  ASSIGNMENT_LIST: ARRAY(init.assignmentList),
};

configureModel(model);

export default model;
