import React from 'react';
import {connect} from 'react-redux';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Media, Spinner, Tooltip} from 'reactstrap';
import {setisClassListVisible} from '../../redux/actions/classList';
import {
  fetchAndOpenClass,
  setIsClassModalEditMode,
  setIsClassModalVisible,
} from '../../redux/actions/class';
import {
  isLoggedInSelector,
  sortedClassListSelector,
  openClassIdSelector,
  isClassListLoadingSelector,
  isClassListVisibleSelector,
} from '../../redux/selectors';
import foxLogo from '../../assets/foxLogo.png';

export function ClassesDropdown({
  isLoggedIn,
  isClassListVisible,
  isClassListLoading,
  classList,
  openClassId,
  fetchAndOpenClass,
  setIsClassModalEditMode,
  setIsClassModalVisible,
  setisClassListVisible,
}) {
  const handleOpenClass = event => {
    const {id} = event.target;

    if (id !== openClassId) {
      fetchAndOpenClass(id);
    }
  };

  const handleNewClass = () => {
    setIsClassModalEditMode(false);
    setIsClassModalVisible(true);
  };

  const menuItems = isClassListLoading ?
    <DropdownItem><Spinner /></DropdownItem> :
    [
      ...classList.map(({id, name}) =>
        <DropdownItem key={id} id={id} onClick={handleOpenClass}>{name}</DropdownItem>
      ),
      <DropdownItem key='addclass' onClick={handleNewClass}>Create a Class</DropdownItem>,
    ];

  const handleToggleDropdown = () => setisClassListVisible(!isClassListVisible);

  return (
    <Dropdown isOpen={isClassListVisible && isLoggedIn} toggle={handleToggleDropdown}>
      <DropdownToggle color='primary' id='ClassListToggler'>
        <Media object src={foxLogo} />
        <h3 className='bold'>Charmed</h3>
        <Tooltip isOpen={isClassListVisible && !isLoggedIn} target='ClassListToggler'>
          Please log in to save classes
        </Tooltip>
      </DropdownToggle>
      <DropdownMenu>
        {menuItems}
      </DropdownMenu>
    </Dropdown>
  );
}

const stateToProps = state => ({
  isLoggedIn: isLoggedInSelector(state),
  isClassListVisible: isClassListVisibleSelector(state),
  isClassListLoading: isClassListLoadingSelector(state),
  openClassId: openClassIdSelector(state),
  classList: sortedClassListSelector(state),
});

const dispatchToProps = {
  fetchAndOpenClass,
  setisClassListVisible,
  setIsClassModalEditMode,
  setIsClassModalVisible,
};

export default connect(stateToProps, dispatchToProps)(ClassesDropdown);
