import firebase from 'firebase/compat/app';
import authConfig from '../../config/auth';
import store from '../redux/store';
import {handleLogIn} from '../redux/actions/auth';

firebase.initializeApp(authConfig);

function checkIfAuthenticated() {
  return new Promise((resolve, reject) => {
    const channel = new MessageChannel();

    // Handler for recieving message reply from service worker
    channel.port1.onmessage = event => {
      if (event.data.error) {
        reject(event.data.error);
      }
      else {
        if (event.data.isAuthenticated) {
          store.dispatch(handleLogIn());
        }
        resolve();
      }
    };

    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({isCharmedChallenge: true}, [channel.port2]);
    }
  });
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('message', checkIfAuthenticated);
  navigator.serviceWorker.register('/service-worker.js', {scope: '/'}).
    then(reg => {
      console.log(`SW Registration succeeded. Scope is ${reg.scope}`);
      return navigator.serviceWorker.ready;
    }).
    then(checkIfAuthenticated).
    catch(error => {
      console.error(`SW Registration failed with ${error.message}`);
    });
}
else if (window.location.pathname !== '/unsupported') {
  window.location.assign('/unsupported');
}
