import React from 'react';
import {connect} from 'react-redux';
import GeneratedGroup from './GeneratedGroup';
import {assignmentGroupsSelector} from '../../redux/selectors';

const GeneratedGroups = ({generatedGroups}) =>
  <div id='GeneratedGroups'>
    {
      generatedGroups.map((studentList, index) =>
        <GeneratedGroup
          key={studentList[0].id}
          index={index}
          studentList={studentList}
        />
      )
    }
  </div>

;


const stateToProps = state => ({
  generatedGroups: assignmentGroupsSelector(state),
});

export default connect(stateToProps)(GeneratedGroups);
