import React from 'react';
import {Label} from 'reactstrap';
import classnames from 'classnames';

export const RadioButtonGroup = ({
  color = 'primary',
  options,
  selectedOption,
  onChange,
}) => {
  const optionButtons = options.map((option, index) =>
    <Label
      key={option}
      className={classnames({
        btn: true,
        [`btn-${color}`]: true,
        active: selectedOption === index,
        'nav-link': true,
      })}
      onClick={() => onChange(index)}
    >
      {option}
    </Label>
  );

  return (
    <div className='btn-group btn-group-toggle radio-button-group' data-toggle='buttons'>
      {optionButtons}
    </div>
  );
};

export default RadioButtonGroup;
