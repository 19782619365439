import axios from 'axios';
import uuid from 'uuid/v4';
import {ERROR} from '../../utils/constants';
import model from '../reducers/model';
import analytics from '../../utils/analytics';
import {openClass, closeClass} from './class';
import save from './save';
import {
  isLoggedInSelector,
  openClassIdSelector,
  classDataSelector,
} from '../selectors';
import {API_ORIGIN} from '../../constants';

const {STATUS, IS_CLASS_LIST_VISIBLE, OPEN_CLASS, IS_CLASS_LIST_LOADING, CLASS_LIST} = model;

export const setisClassListVisible = isOpen => dispatch => {
  dispatch(IS_CLASS_LIST_VISIBLE.SET, isOpen);
};

export const fetchClassList = () => async dispatch => {
  dispatch(IS_CLASS_LIST_LOADING.SET, true);

  try {
    const response = await axios.get(`${API_ORIGIN}/classes`);
    const classList = response.data || {};

    classList.forEach(entry => {
      dispatch(CLASS_LIST.SET, entry);
    });
    dispatch(IS_CLASS_LIST_LOADING.SET, false);
  }
  catch (error) {
    analytics.class.error('classList.fetchClassList');
    dispatch(STATUS.SET, ERROR);
  }
};

export const createClass = classData => async(dispatch, getState) => {
  const isLoggedIn = isLoggedInSelector(getState());

  try {
    dispatch(openClass({
      id: uuid(),
      name: classData.name,
      studentList: classData.studentList,
    }));
    analytics.class.create(classData.studentList.length);
    if (isLoggedIn) {
      await dispatch(save());
    }
  }
  catch (error) {
    analytics.class.error('classList.createClass');
    dispatch(STATUS.SET, ERROR);
  }
};

export const updateClass = newClassData => async(dispatch, getState) => {
  dispatch(OPEN_CLASS.IS_LOADING.SET, true);
  const state = getState();
  const isLoggedIn = isLoggedInSelector(state);
  const oldClassData = classDataSelector(state);
  const oldRuleList = oldClassData.ruleList;

  // Get rid of any rules that no longer have any students
  const newRuleList = oldRuleList.filter(rule => {
    const newIdsForRule = rule.studentList.filter(studentId =>
      newClassData.studentList.some(student => student.id === studentId)
    );

    return newIdsForRule.length > 0;
  });

  try {
    dispatch(openClass({
      id: oldClassData.id,
      name: newClassData.name,
      studentList: newClassData.studentList,
      ruleList: newRuleList,
      assignmentList: oldClassData.assignmentList,
    }));
    analytics.class.update(newClassData.studentList.length);
    if (isLoggedIn) {
      await dispatch(save());
    }
  }
  catch (error) {
    analytics.class.error('classList.updateClass');
    dispatch(STATUS.SET, ERROR);
  }
};

export const deleteClass = () => async(dispatch, getState) => {
  dispatch(OPEN_CLASS.IS_LOADING.SET, true);
  const isLoggedIn = isLoggedInSelector(getState());

  try {
    if (isLoggedIn) {
      const id = openClassIdSelector(getState());

      await axios.delete(`${API_ORIGIN}/classes/${id}`);
      const entry = {id};

      dispatch(CLASS_LIST.UNSET, entry);
    }
    dispatch(OPEN_CLASS.IS_LOADING.SET, false);
    analytics.class.delete();
  }
  catch (error) {
    analytics.class.error('classList.deleteClass');
    dispatch(STATUS.SET, ERROR);
  }
  dispatch(closeClass());
};
