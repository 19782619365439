import React, {Component} from 'react';
import {connect} from 'react-redux';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import Group from './Group';
import {groupSizesSelector} from '../../../../redux/selectors';

export class SizesPreview extends Component {
  state = {maxWidth: 0};
  containerRef = React.createRef();

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.numStudents !== nextProps.numStudents ||
      this.state.maxWidth !== nextState.maxWidth ||
      !_isEqual(this.props.groupSizes, nextProps.groupSizes)
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.setState({maxWidth: _get(this.containerRef, 'current.clientWidth', 0)});
  };

  render() {
    const {maxWidth} = this.state;
    const {groupSizes} = this.props;
    const groups = groupSizes.map((groupSize, index) =>
      <Group key={index} groupSize={groupSize} maxWidth={maxWidth} />
    );

    return (
      <div id='GroupSizesPreviewContainer' ref={this.containerRef}>
        {maxWidth && groups}
      </div>
    );
  }
}

const stateToProps = state => ({
  groupSizes: groupSizesSelector(state),
});

export default connect(stateToProps)(SizesPreview);
