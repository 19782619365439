import model from '../reducers/model';
import {TARGETING_MODE} from '../../utils/constants';
import {
  studentListSelector,
  targetGroupSizeSelector,
  targetingModeSelector,
  presentStudentCountSelector,
  targetGroupCountSelector,
} from '../selectors';

const {STUDENT_LIST, OPEN_ASSIGNMENT} = model;

export const updateStudent = entry => dispatch => {
  dispatch(STUDENT_LIST.SET, entry);
};

export const toggleStudentAbsent = id => (dispatch, getState) => {
  const state = getState();
  const student = studentListSelector(state).find(student => student.id === id);
  const {isPresent} = student;
  const targetingMode = targetingModeSelector(state);
  const targetGroupCount = targetGroupCountSelector(state);
  const targetGroupSize = targetGroupSizeSelector(state);
  const presentStudentCount = presentStudentCountSelector(state);

  // Only set to absent if more than 2 are present
  if (!student.isPresent || presentStudentCount > 2) {
    if (isPresent) {
      // If the marking absent and target group size is maxed out then reduce it by 1
      if (targetingMode === TARGETING_MODE.STUDENTS_PER_GROUP && targetGroupSize >= presentStudentCount) {
        dispatch(OPEN_ASSIGNMENT.TARGET_GROUP_SIZE.SET, presentStudentCount - 1);
      }
      else if (targetingMode === TARGETING_MODE.GROUP_COUNT && targetGroupCount >= presentStudentCount) {
        dispatch(OPEN_ASSIGNMENT.TARGET_GROUP_COUNT.SET, presentStudentCount - 1);
      }
    }
    const entry = {
      ...student,
      isPresent: !student.isPresent,
    };

    dispatch(STUDENT_LIST.SET, entry);
  }
};
