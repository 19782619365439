import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Button, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav} from 'reactstrap';
import ClassesDropdown from './ClassesDropdown';
import EditClassButton from './EditClassButton';
import AssignmentsDropdown from './AssignmentsDropdown';
import AuthButton from './AuthButton';
import {hasOpenClassSelector, hasAssignmentsSelector} from '../../redux/selectors';

export function MyNavbar({hasOpenClass, hasAssignments}) {
  const [isOpen, toggleOpen] = useState(false);
  const openClassButtons = hasOpenClass ?
    <>
      <EditClassButton />
      {hasAssignments && <AssignmentsDropdown />}
    </> :
    <div />;

  const openPersonalWebsite = ()=> {
    window.open('https://caseyvangroll.com', '_blank');
  };

  return (
    <Navbar color='primary' light expand='md'>
      <NavbarBrand id='Brand'>
        <ClassesDropdown />
      </NavbarBrand>
      <NavbarToggler onClick={() => toggleOpen(!isOpen)} />
      <Collapse isOpen={isOpen} navbar>
        <Nav id='CollapsibleNav' navbar>
          {openClassButtons}
          <div id='RightSideItems'>
            <AuthButton />
            <Button color='primary' onClick={openPersonalWebsite}>
              Made by Casey
            </Button>
          </div>
        </Nav>
      </Collapse>
    </Navbar>
  );
}

const stateToProps = state => ({
  hasOpenClass: hasOpenClassSelector(state),
  hasAssignments: hasAssignmentsSelector(state),
});

export default connect(stateToProps)(MyNavbar);
