import React from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import classnames from 'classnames';
import {Nav, NavItem, NavLink} from 'reactstrap';

function OpenClassNav({pathname, push}) {
  return (
    <Nav fill>
      <NavItem id='GenerateTab'>
        <NavLink
          className={classnames({active: pathname === '/generate', 'open-class-tab': true})}
          onClick={() => push('/generate')}
        >
          Generate
        </NavLink>
      </NavItem>
      <NavItem id='RulesTab'>
        <NavLink
          className={classnames({active: pathname === '/rules', 'open-class-tab': true})}
          onClick={() => push('/rules')}
        >
          Rules
        </NavLink>
      </NavItem>

    </Nav>
  );
}


const stateToProps = state => ({
  pathname: state.router.location.pathname,
});

const dispatchToProps = {push};

export default connect(stateToProps, dispatchToProps)(OpenClassNav);
