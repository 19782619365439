import React from 'react';
import {Button} from 'reactstrap';

export const Rule = ({
  shouldGroup,
  studentList,
  onClick,
}) => {
  const studentLabels = studentList.map(student =>
    <h6 key={student.id}>{student.id}</h6>
  );

  return (
    <Button
      color={shouldGroup ? 'primary' : 'danger'}
      className='rule skinny-panel'
      onClick={onClick}
    >
      {studentLabels}
    </Button>
  );
};


export default Rule;
