import React from 'react';
import {getGridPosition} from './config';

export const Student = ({cols, index}) => {
  const {row, col} = getGridPosition(cols, index);

  return (
    <div
      className='group-sizes-preview-student'
      style={{
        gridColumnStart: col,
        gridColumnEnd: col + 1,
        gridRowStart: row,
        gridRowEnd: row + 1,
      }}
    />
  );
};
export default Student;
