
export const CELL_SIZE = 25;
export const GRID_GAP = 6;

export const getGridDimensions = (maxWidth, numStudents, studentWidth = CELL_SIZE, gridGap = GRID_GAP) => {
  let numRows = Math.floor(Math.sqrt(numStudents));

  while (

    Math.ceil(numStudents / numRows) * studentWidth + // Width of students themselves
      Math.ceil(numStudents / numRows) + 1 * gridGap > // Width of padding around them
     maxWidth
  ) {
    numRows += 1;
  }
  return {
    rows: numRows,
    cols: Math.ceil(numStudents / numRows),
  };
};

// Takes in 0-indexed student index, returns 1-indexed grid position
export const getGridPosition = (cols, index) => ({
  row: Math.ceil((index + 1) / cols),
  col: index % cols + 1,
});
