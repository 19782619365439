import makeGroups from 'charm-engine';
import uuid from 'uuid/v4';
import model from '../reducers/model';
import save from './save';
import analytics from '../../utils/analytics';
import {
  presentStudentListSelector,
  groupSizesSelector,
  assignmentListSelector,
  assignmentGroupsSelector,
  openAssignmentIdSelector,
  ruleListSelector,
} from '../selectors';

const {OPEN_ASSIGNMENT, ASSIGNMENT_LIST} = model;

export const setIsAssignmentModalVisible = isVisible => dispatch => {
  if (isVisible) {
    document.getElementsByTagName('html')[0].style.overflow = 'hidden';
  }
  else {
    document.getElementsByTagName('html')[0].style.overflow = 'auto';
  }
  dispatch(OPEN_ASSIGNMENT.IS_MODAL_VISIBLE.SET, isVisible);
};

export const setTargetingMode = targetingMode => dispatch => {
  dispatch(OPEN_ASSIGNMENT.TARGETING_MODE.SET, targetingMode);
};

export const setTargetGroupSize = targetGroupSize => dispatch => {
  dispatch(OPEN_ASSIGNMENT.TARGET_GROUP_SIZE.SET, targetGroupSize);
};

export const setTargetGroupCount = targetGroupCount => dispatch => {
  dispatch(OPEN_ASSIGNMENT.TARGET_GROUP_COUNT.SET, targetGroupCount);
};

export const openAssignmentById = assignmentId => (dispatch, getState) => {
  const state = getState();
  const assignmentList = assignmentListSelector(state);
  const assignment = assignmentList.find(assignment => assignment.id === assignmentId);

  dispatch(OPEN_ASSIGNMENT.IS_SAVED.SET, true);
  dispatch(OPEN_ASSIGNMENT.ID.SET, assignmentId);
  dispatch(OPEN_ASSIGNMENT.NAME.SET, assignment.name);
  dispatch(OPEN_ASSIGNMENT.GROUPS.SET, assignment.groups);
  dispatch(OPEN_ASSIGNMENT.IS_MODAL_VISIBLE.SET, true);
  analytics.assignment.open();
};

export const saveAssignment = name => async(dispatch, getState) => {
  const id = uuid();
  const groups = assignmentGroupsSelector(getState());

  dispatch(OPEN_ASSIGNMENT.ID.SET, id);
  dispatch(OPEN_ASSIGNMENT.NAME.SET, name);
  dispatch(OPEN_ASSIGNMENT.IS_SAVED.SET, true);

  const entry = {id, name, groups};

  dispatch(ASSIGNMENT_LIST.SET, entry);
  await dispatch(save());
};

export const deleteAssignment = () => async(dispatch, getState) => {
  const id = openAssignmentIdSelector(getState());

  dispatch(OPEN_ASSIGNMENT.ID.UNSET);
  dispatch(OPEN_ASSIGNMENT.NAME.UNSET);
  dispatch(OPEN_ASSIGNMENT.IS_SAVED.SET, false);

  const entry = {id};

  dispatch(ASSIGNMENT_LIST.UNSET, entry);
  dispatch(OPEN_ASSIGNMENT.IS_MODAL_VISIBLE.SET, false);
  analytics.assignment.delete();
  await dispatch(save());
};

export const generateGroups = () => (dispatch, getState) => {
  const state = getState();
  const presentStudentList = presentStudentListSelector(state);
  const presentStudentIds = presentStudentList.map(student => student.id);
  const groupSizes = groupSizesSelector(state);
  const ruleList = ruleListSelector(state);

  console.log(ruleList);
  const rules = ruleList.map(rule => ({
    shouldGroup: rule.shouldGroup,
    IDs: rule.studentList.map(student => student.id).filter(id => presentStudentIds.includes(id)),
  })).filter(rule => rule.IDs.length > 1);

  console.log(rules);
  const generatedGroupsOfIds = makeGroups(presentStudentIds, groupSizes, rules);
  const generatedGroups = generatedGroupsOfIds.map(generatedGroupOfIds =>
    generatedGroupOfIds.map(studentId =>
      presentStudentList.find(student => student.id === studentId)
    )
  );

  const avgGroupSize = groupSizes.reduce((a, b) => a + b, 0) / groupSizes.length;

  analytics.assignment.create(Math.round(avgGroupSize));
  dispatch(OPEN_ASSIGNMENT.IS_SAVED.SET, false);
  dispatch(OPEN_ASSIGNMENT.GROUPS.SET, generatedGroups);
};
