import React from 'react';
import {connect} from 'react-redux';
import {Container, Jumbotron, Button} from 'reactstrap';
import {isLoggedInSelector} from '../../redux/selectors';
import {setIsClassModalVisible, setIsClassModalEditMode} from '../../redux/actions/class';
import {setisClassListVisible} from '../../redux/actions/classList';

export const WelcomeJumbotron = ({
  isLoggedIn,
  setIsClassModalVisible,
  setIsClassModalEditMode,
  setisClassListVisible,
}) => {
  const title = isLoggedIn ? 'Jump back in.' : 'Welcome!';

  const body = isLoggedIn ?
    'Those groups aren\'t gonna make themselves!' :
    'Charmed is a teacher tool for creating and saving groups of students.';

  const createLabel = `Create ${isLoggedIn ? 'a' : 'your first'} class`;

  const handleCreateClass = () => {
    setIsClassModalEditMode(false);
    setIsClassModalVisible(true);
  };

  const buttons =
    <div id='welcome-buttons'>
      <Button color='primary' onClick={handleCreateClass}>{createLabel}</Button>
      {isLoggedIn &&
        <Button
          id='welcome-open-existing'
          color='secondary'
          onClick={() => setisClassListVisible(true)}
        >
          Open an existing class
        </Button>
      }
    </div>

  ;

  return (
    <Container id='WelcomePage' className='page'>
      <Jumbotron>
        <h1 className='display-5'>{title}</h1>
        <p className='lead'>{body}</p>
        <hr className='my-2' />
        {buttons}
      </Jumbotron>
    </Container>
  );
};

const stateToProps = state => ({
  isLoggedIn: isLoggedInSelector(state),
});

const dispatchToProps = {
  setIsClassModalVisible,
  setIsClassModalEditMode,
  setisClassListVisible,
};

export default connect(stateToProps, dispatchToProps)(WelcomeJumbotron);
