import React from 'react';
import {Card, CardHeader, Button} from 'reactstrap';
import classnames from 'classnames';

export const StudentsButtonList = ({
  color = 'primary',
  header,
  studentList,
  toggleStudentActive,
}) =>
  <Card className='student-button-list'>
    <CardHeader>
      <h5>{header}</h5>
    </CardHeader>
    {
      studentList.map(({id, isActive}) =>
        <Button
          key={id}
          color={color}
          className={classnames({isActive})}
          onClick={() => toggleStudentActive(id)}
        >
          {id}
        </Button>
      )
    }
  </Card>
;

export default StudentsButtonList;
