import React from 'react';
import {Modal, ModalHeader, ModalFooter, Button} from 'reactstrap';

export function ConfirmationDialog({
  isOpen,
  isConfirmDisabled,
  onConfirm = () => {},
  onCancel = () => {},
  handleClose,
  headerText = 'Are you sure?',
  confirmText = 'Confirm',
  cancelText,
  children,
}) {
  const handleConfirm = () => {
    handleClose();
    onConfirm();
  };

  const handleCancel = () => {
    handleClose();
    onCancel();
  };

  return (
    <Modal isOpen={isOpen} toggle={handleCancel} className='confirm-modal'>
      <ModalHeader toggle={handleCancel}>{headerText}</ModalHeader>
      {children}
      <ModalFooter>
        <div />
        <div>
          <Button color='primary' disabled={isConfirmDisabled} onClick={handleConfirm}>{confirmText}</Button>
          {Boolean(cancelText) && <Button color='light' onClick={handleCancel}>{cancelText}</Button>}
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default ConfirmationDialog;
