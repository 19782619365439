import model from '../reducers/model';
import uuid from 'uuid/v4';
import analytics from '../../utils/analytics';
import {
  openRuleSelector,
  ruleListSelector,
} from '../selectors';

const {OPEN_RULE, RULE_LIST} = model;

const clearOpenRule = () => dispatch => {
  dispatch(OPEN_RULE.ID.UNSET);
  dispatch(OPEN_RULE.SHOULD_GROUP.SET, true);
  dispatch(OPEN_RULE.STUDENT_LIST.CLEAR);
  dispatch(OPEN_RULE.IS_MODAL_VISIBLE.SET, false);
};

export const keepOpenRule = () => (dispatch, getState) => {
  const entry = openRuleSelector(getState());

  entry.id = entry.id || uuid();
  analytics.rule.keep(entry.studentList.length, entry.shouldGroup ? 'Do Group' : 'Don\'t Group');
  dispatch(RULE_LIST.SET, entry);
  dispatch(clearOpenRule());
};

export const removeOpenRule = () => (dispatch, getState) => {
  const entry = openRuleSelector(getState());

  dispatch(RULE_LIST.UNSET, entry);
  dispatch(clearOpenRule());
};

export const addRule = shouldGroup => dispatch => {
  dispatch(OPEN_RULE.IS_MODAL_EDIT_MODE.SET, false);
  dispatch(OPEN_RULE.SHOULD_GROUP.SET, shouldGroup);
  dispatch(OPEN_RULE.IS_MODAL_VISIBLE.SET, true);
};

export const editRule = ruleId => (dispatch, getState) => {
  const ruleList = ruleListSelector(getState());
  const openRule = ruleList.find(rule => rule.id === ruleId);
  const {id, shouldGroup, studentList} = openRule;

  dispatch(OPEN_RULE.ID.SET, id);
  dispatch(OPEN_RULE.IS_MODAL_EDIT_MODE.SET, true);
  dispatch(OPEN_RULE.SHOULD_GROUP.SET, shouldGroup);
  studentList.forEach(student => {
    const entry = {id: student.id};

    dispatch(OPEN_RULE.STUDENT_LIST.SET, entry);
  });
  dispatch(OPEN_RULE.IS_MODAL_VISIBLE.SET, true);
};

export const toggleStudentForOpenRule = studentId => (dispatch, getState) => {
  const openRule = openRuleSelector(getState());
  const wasSelected = openRule.studentList.some(student => student.id === studentId);
  const entry = {id: studentId};

  if (wasSelected) {
    dispatch(OPEN_RULE.STUDENT_LIST.UNSET, entry);
  }
  else {
    dispatch(OPEN_RULE.STUDENT_LIST.SET, entry);
  }
};
