/* eslint-disable camelcase */
import store from '../redux/store';
import {isLoggedInSelector} from '../redux/selectors';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';

const getAuthStatus = () => {
  if (!isLoggedInSelector(store.getState())) {
    return 'Anonymous';
  }
  return undefined;
};

const send = (
  event_category,
  action,
  value,
  event_label = getAuthStatus(),
) => {
  if (/https:\/\/charmed\.cloud/.test(window?.location?.origin)) {
    firebase.analytics().logEvent(action, {
      event_category,
      value,
      event_label,
    });
  }
};

export default {
  account: {
    signUp: () => send('Account', 'Sign up'),
    logIn: () => send('Account', 'Log In'),
    error: actionType => send('Account', 'Error', undefined, actionType),
  },

  class: {
    create: studentCt => send('Class', 'Create', studentCt),
    update: studentCt => send('Class', 'Update', studentCt),
    save: () => send('Class', 'Save'),
    open: () => send('Class', 'Open'),
    delete: () => send('Class', 'Delete'),
    error: actionType => send('Class', 'Error', undefined, actionType),
  },

  assignment: {
    create: avgGroupSize => send('Assignment', 'Create', avgGroupSize),
    open: () => send('Assignment', 'Open'),
    delete: () => send('Assignment', 'Delete'),
  },

  rule: {
    keep: (studentCt, ruleType) => send('Rule', 'Keep', studentCt, ruleType),
  },

  feedback: {
    error: () => send('Feedback', 'Error'),
  },
};
