module.exports = {
  apiKey: 'AIzaSyDWXyiSzqq-MZTXkKA3TwumHhwg52FGmQs',
  authDomain: 'charmed-cloud.firebaseapp.com',
  databaseURL: 'https://charmed-cloud.firebaseio.com',
  projectId: 'charmed-cloud',
  storageBucket: 'charmed-cloud.appspot.com',
  messagingSenderId: '684620853915',
  appId: '1:684620853915:web:a42f8688b08b7a74',
  measurementId: 'G-T6SQBFW2NW',
};
