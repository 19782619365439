import React from 'react';

const GeneratedGroup = ({
  index,
  studentList,
}) =>
  <div className='generated-group'>
    <div className='generated-group-label'>
      <h6>{`Group ${index + 1}`}</h6>
    </div>
    <div className='generated-group-students'>
      {
        studentList.map(student =>
          <div key={student.id}>
            <h6>{student.id}</h6>
          </div>
        )
      }
    </div>
  </div>

;

export default GeneratedGroup;
