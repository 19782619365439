import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Input} from 'reactstrap';
import Octicon, {ScreenFull, ScreenNormal, Sync} from '@primer/octicons-react';
import classnames from 'classnames';
import {ConfirmationDialog} from '..';
import GeneratedGroups from './GeneratedGroups';
import {
  isAssignmentSavedSelector,
  openAssignmentNameSelector,
  isLoggedInSelector,
} from '../../redux/selectors';
import {
  setIsAssignmentModalVisible,
  generateGroups,
  saveAssignment,
  deleteAssignment,
} from '../../redux/actions/assignment';

const AssignmentModal = ({
  isLoggedIn,
  openAssignmentName,
  isAssignmentSaved,
  generateGroups,
  saveAssignment,
  deleteAssignment,
  setIsAssignmentModalVisible,
}) => {
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [assignmentName, setAssignmentName] = useState('');
  const [isMaximized, setIsMaximized] = useState(false);

  const handleClose = () => setIsAssignmentModalVisible(false);

  const saveOrDeleteButton = isAssignmentSaved ?
    <Button color='danger' onClick={() => setIsDeleteModalOpen(true)}>
      Delete
    </Button>     :
    <Button color='success' onClick={() => setIsSaveModalOpen(true)}>
      Save
    </Button>

  ;

  const regenerateButton = !isAssignmentSaved &&
  <Button
    color='primary'
    onClick={() => generateGroups()}
  >
    <Octicon icon={Sync} size='medium' />
  </Button>

  ;

  const expandContractButton =
    <Button
      color='light'
      id='ExpandContractButton'
      onClick={() => setIsMaximized(!isMaximized)}
    >
      <Octicon icon={isMaximized ? ScreenNormal : ScreenFull} size='medium' />
    </Button>

  ;

  const spacer = <div className='btn'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>;

  return (
    <Modal
      isOpen
      centered
      size='lg'
      toggle={handleClose}
      id='GeneratedGroupsModal'
      className={classnames({isMaximized})}
    >
      <ModalHeader id='AssignmentHeader'>
        {spacer}
        {openAssignmentName || ''}
        {expandContractButton}
      </ModalHeader>
      <ModalBody>
        <GeneratedGroups />
      </ModalBody>
      <ModalFooter id='AssignmentModalFooter'>
        {isLoggedIn ? saveOrDeleteButton : spacer}
        {regenerateButton}
        <Button
          color='light'
          onClick={handleClose}
        >
          Done
        </Button>
      </ModalFooter>
      <ConfirmationDialog
        isOpen={isSaveModalOpen}
        isConfirmDisabled={!assignmentName}
        onConfirm={() => saveAssignment(assignmentName)}
        handleClose={() => setIsSaveModalOpen(false)}
        headerText='Enter name for assignment'
        cancelText='Cancel'
      >
        <ModalBody>
          <Input
            type='text'
            value={assignmentName}
            onChange={event => setAssignmentName(event.target.value)}
          />
        </ModalBody>
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={isDeleteModalOpen}
        onConfirm={deleteAssignment}
        handleClose={() => setIsDeleteModalOpen(false)}
        headerText='Are you sure you want to delete this assignment?'
        cancelText='Cancel'
      />
    </Modal>
  );
};

const stateToProps = state => ({
  isLoggedIn: isLoggedInSelector(state),
  openAssignmentName: openAssignmentNameSelector(state),
  isAssignmentSaved: isAssignmentSavedSelector(state),
});

const dispatchToProps = {
  generateGroups,
  setIsAssignmentModalVisible,
  saveAssignment,
  deleteAssignment,
};

export default connect(stateToProps, dispatchToProps)(AssignmentModal);
