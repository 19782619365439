import axios from 'axios';
import {push} from 'connected-react-router';
import {ERROR} from '../../utils/constants';
import model from '../reducers/model';
import analytics from '../../utils/analytics';
import {API_ORIGIN} from '../../constants';

const {STATUS, STUDENT_LIST, RULE_LIST, OPEN_CLASS, ASSIGNMENT_LIST, OPEN_ASSIGNMENT} = model;

export const setIsClassModalVisible = isVisible => dispatch => {
  dispatch(OPEN_CLASS.IS_MODAL_VISIBLE.SET, isVisible);
};

export const setIsClassModalEditMode = isEditMode => dispatch => {
  dispatch(OPEN_CLASS.IS_MODAL_EDIT_MODE.SET, isEditMode);
};

export const openClass = classData => dispatch => {
  const {
    id,
    name,
    studentList = [],
    ruleList = [],
    assignmentList = [],
  } = classData;

  dispatch(STUDENT_LIST.CLEAR);
  studentList.forEach(entry => {
    dispatch(STUDENT_LIST.SET, entry);
  });

  dispatch(RULE_LIST.CLEAR);
  ruleList.forEach(entry => {
    dispatch(RULE_LIST.SET, entry);
  });

  dispatch(ASSIGNMENT_LIST.CLEAR);
  assignmentList.forEach(entry => {
    dispatch(ASSIGNMENT_LIST.SET, entry);
  });

  dispatch(OPEN_CLASS.ID.SET, id);
  dispatch(OPEN_CLASS.NAME.SET, name);
  dispatch(OPEN_ASSIGNMENT.TARGET_GROUP_SIZE.SET, 2);
  dispatch(OPEN_ASSIGNMENT.TARGET_GROUP_COUNT.SET, 2);
  dispatch(OPEN_CLASS.IS_LOADING.SET, false);
  dispatch(push('/generate'));
};

export const fetchAndOpenClass = id => async dispatch => {
  dispatch(OPEN_CLASS.IS_LOADING.SET, true);

  try {
    const response = await axios.get(`${API_ORIGIN}/classes/${id}`);

    analytics.class.open();
    dispatch(openClass({
      id,
      ...response.data || {},
    }));
  }
  catch (error) {
    analytics.class.error('class.fetchAndOpenClass');
    dispatch(STATUS.SET, ERROR);
  }
};

export const closeClass = () => dispatch => {
  dispatch(OPEN_CLASS.ID.UNSET);
  dispatch(OPEN_CLASS.NAME.UNSET);
  dispatch(RULE_LIST.CLEAR);
  dispatch(STUDENT_LIST.CLEAR);
  dispatch(push('/'));
};
