import React from 'react';
import {connect} from 'react-redux';
import {Container, Row, Col, Button} from 'reactstrap';
import Octicon, {Plus} from '@primer/octicons-react';
import Rule from './Rule';
import {
  addRule,
  editRule,
} from '../../redux/actions/rule';
import {
  shouldGroupRuleListSelector,
  shouldNotGroupRuleListSelector,
} from '../../redux/selectors';

export const RulesPage = ({
  shouldGroupRuleList,
  shouldNotGroupRuleList,
  addRule,
  editRule,
}) => {
  const shouldGroupRules = shouldGroupRuleList.map(rule =>
    <Rule
      key={rule.id}
      id={rule.id}
      shouldGroup
      studentList={rule.studentList}
      onClick={() => editRule(rule.id)}
    />
  );
  const shouldNotGroupRules = shouldNotGroupRuleList.map((rule, i) =>
    <Rule
      key={i}
      studentList={rule.studentList}
      onClick={() => editRule(rule.id)}
    />
  );

  return (
    <Container id='RulesPage' className='page'>
      <Row>
        <Col md='6' className='centered-column'>
          <div className='centered-column athletic-panel'>
            <h3>Do group</h3>
            {shouldGroupRules}
            <Button
              color='primary'
              className='plus-button'
              onClick={() => addRule(true)}
            >
              <Octicon icon={Plus} size='small' />
            </Button>
          </div>
        </Col>
        <Col md='6' className='centered-column'>
          <div className='centered-column athletic-panel'>
            <h3>Don&apos;t group</h3>
            {shouldNotGroupRules}
            <Button
              color='danger'
              className='plus-button'
              onClick={() => addRule(false)}
            >
              <Octicon icon={Plus} size='small' />
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const stateToProps = state => ({
  shouldGroupRuleList: shouldGroupRuleListSelector(state),
  shouldNotGroupRuleList: shouldNotGroupRuleListSelector(state),
});

const dispatchToProps = {
  addRule,
  editRule,
};

export default connect(stateToProps, dispatchToProps)(RulesPage);
