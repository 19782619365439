import axios from 'axios';
import analytics from '../../utils/analytics';
import model from '../reducers/model';
import {ERROR} from '../../utils/constants';
import {isClassModalEditModeSelector, sanitizedClassDataSelector} from '../selectors';
import {API_ORIGIN} from '../../constants';

const {STATUS, CLASS_LIST} = model;

export const save = () => async(dispatch, getState) => {
  const state = getState();
  const isClassModalEditMode = isClassModalEditModeSelector(state);
  const classData = sanitizedClassDataSelector(state);

  try {
    if (isClassModalEditMode) {
      await axios.put(`${API_ORIGIN}/classes/${classData.id}`, classData);
    }
    else {
      await axios.post(`${API_ORIGIN}/classes`, classData);
    }

    const entry = {
      id: classData.id,
      name: classData.name,
    };

    dispatch(CLASS_LIST.SET, entry);
    analytics.class.save();
  }
  catch (e) {
    analytics.class.error('save');
    dispatch(STATUS.SET, ERROR);
  }
};

export default save;
