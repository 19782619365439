import React from 'react';
import {Container, Row, Col} from 'reactstrap';
import StudentsPanel from './StudentsPanel';
import SizeControls from './GeneratePanel/SizeControls';
import SizesPreview from './GeneratePanel/SizesPreview';

export function GeneratePage() {
  return (
    <Container id='ClassPage' className='page'>
      <Row>
        <Col xl='6' lg='6' md='6' className='centered-column'>
          <StudentsPanel />
        </Col>
        <Col xl='4' lg='5' md='6' className='centered-column'>
          <SizeControls />
          <SizesPreview />
        </Col>
      </Row>
    </Container>
  );
}

export default GeneratePage;
