import {createSelector} from 'reselect';
import {determineGroupSizes, determineGroupSizesByGroupCount} from 'charm-engine';
import {ERROR, TARGETING_MODE} from '../utils/constants';

// Status
const getStatus = state => state.status;

export const hasErrorSelector = createSelector([getStatus], status => status === ERROR);

// Auth
export const isLoggedInSelector = state => state.isLoggedIn;

// Class List
export const classListSelector = state => state.classList;
export const sortedClassListSelector = createSelector(
  [classListSelector],
  classList => classList.sort((classA, classB) => classA.name < classB.name ? -1 : 1),
);
export const isClassListVisibleSelector = state => state.isClassListVisible;
export const isClassListLoadingSelector = state => state.isClassListLoading;

// Class
export const openClassIdSelector = state => state.openClass.id;
export const openClassNameSelector = state => state.openClass.name;
export const isOpenClassLoadingSelector = state => state.openClass.isLoading;
export const isClassModalVisibleSelector = state => state.openClass.isModalVisible;
export const isClassModalEditModeSelector = state => state.openClass.isModalEditMode;
export const hasOpenClassSelector = createSelector([openClassNameSelector], Boolean);

// Student List
export const studentListSelector = state => state.studentList;
export const presentStudentListSelector = createSelector(
  [studentListSelector],
  studentList => studentList.filter(student => student.isPresent),
);
export const presentStudentIdsSelector = createSelector(
  [presentStudentListSelector],
  presentStudentList => presentStudentList.map(student => student.id),
);
export const presentStudentCountSelector = createSelector(
  [presentStudentListSelector],
  presentStudentList => presentStudentList.length,
);

// Rule List
export const ruleListSelector = state => state.ruleList;
export const shouldGroupRuleListSelector = createSelector(
  [ruleListSelector],
  ruleList => ruleList.filter(rule => rule.shouldGroup === true),
);
export const shouldNotGroupRuleListSelector = createSelector(
  [ruleListSelector],
  ruleList => ruleList.filter(rule => rule.shouldGroup === false),
);

// Rule
export const openRuleSelector = state => state.openRule;
export const isRuleModalVisibleSelector = state => state.openRule.isModalVisible;
export const isRuleModalEditModeSelector = state => state.openRule.isModalEditMode;

// Assignment List
export const assignmentListSelector = state => state.assignmentList;
export const sortedAssignmentListSelector = createSelector(
  [assignmentListSelector],
  assignmentList => assignmentList.sort((assignmentA, assignmentB) => assignmentA.name < assignmentB.name ? -1 : 1),
);
export const hasAssignmentsSelector = createSelector(
  [assignmentListSelector],
  assignmentList => assignmentList.length > 0,
);

// Assignment
export const openAssignmentIdSelector = state => state.openAssignment.id;
export const openAssignmentNameSelector = state => state.openAssignment.name;
export const isAssignmentModalVisibleSelector = state => state.openAssignment.isModalVisible;
export const isAssignmentSavedSelector = state => state.openAssignment.isSaved;
export const assignmentGroupsSelector = state => state.openAssignment.groups;
export const targetingModeSelector = state => state.openAssignment.targetingMode;
export const targetGroupSizeSelector = state => state.openAssignment.targetGroupSize;
export const targetGroupCountSelector = state => state.openAssignment.targetGroupCount;
export const groupSizesSelector = createSelector(
  [
    presentStudentCountSelector,
    targetingModeSelector,
    targetGroupCountSelector,
    targetGroupSizeSelector,
  ],
  (presentStudentCount, targetingMode, targetGroupCount, targetGroupSize) => {
    if (targetingMode === TARGETING_MODE.STUDENTS_PER_GROUP) {
      return determineGroupSizes(presentStudentCount, targetGroupSize).groups.sort();
    }
    return determineGroupSizesByGroupCount(presentStudentCount, targetGroupCount).groups.sort();
  },
);
export const minGroupSizeSelector = createSelector(
  [groupSizesSelector],
  groupSizes => Math.min(...groupSizes),
);
export const maxGroupSizeSelector = createSelector(
  [groupSizesSelector],
  groupSizes => Math.max(...groupSizes),
);
export const groupCountSelector = createSelector(
  [groupSizesSelector],
  groupSizes => groupSizes.length,
);

// Grabs class data from store to be saved to remote
export const classDataSelector = state => ({
  id: openClassIdSelector(state),
  name: openClassNameSelector(state),
  studentList: studentListSelector(state),
  ruleList: ruleListSelector(state),
  assignmentList: assignmentListSelector(state),
});

export const sanitizedClassDataSelector = createSelector(
  [classDataSelector],
  ({
    studentList,
    ...classData
  }) => ({
    ...classData,
    studentList: studentList.map(student => ({
      ...student,
      isPresent: true,
    })),
  })
);
