import React from 'react';
import {connect} from 'react-redux';
import {Button} from 'reactstrap';
import {setIsClassModalEditMode, setIsClassModalVisible} from '../../redux/actions/class';
import {openClassNameSelector} from '../../redux/selectors';

export function EditClassButton({
  openClassName,
  setIsClassModalEditMode,
  setIsClassModalVisible,
}) {
  const handleEditClass = () => {
    setIsClassModalEditMode(true);
    setIsClassModalVisible(true);
  };

  return (
    <Button color='primary' onClick={handleEditClass}>
      <h4 className='bold'>{openClassName}</h4>
    </Button>
  );
}

const stateToProps = state => ({
  openClassName: openClassNameSelector(state),
});

const dispatchToProps = {
  setIsClassModalEditMode,
  setIsClassModalVisible,
};

export default connect(stateToProps, dispatchToProps)(EditClassButton);
