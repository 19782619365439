import React from 'react';
import {connect} from 'react-redux';
import {Card, CardHeader, CardBody} from 'reactstrap';
import SizesDisplay from './SizesDisplay';
import {
  presentStudentCountSelector,
  minGroupSizeSelector,
  maxGroupSizeSelector,
  targetingModeSelector,
  targetGroupSizeSelector,
  targetGroupCountSelector,
  groupCountSelector,
} from '../../../../redux/selectors';

export const SizesPreview = ({
  presentStudentCount,
  groupCount,
  minGroupSize,
  maxGroupSize,
}) => {
  let groupSizeRange = minGroupSize;

  if (minGroupSize !== maxGroupSize) {
    groupSizeRange = `${minGroupSize}-${maxGroupSize}`;
  }

  const studentCountLabel = `${presentStudentCount} students`;
  const groupSizesLabel = `${groupCount} group${groupCount > 1 ? 's' : ''} of size ${groupSizeRange}`;

  return (
    <Card id='SizesPreview'>
      <CardHeader>
        <h3>{studentCountLabel}</h3>
        <h5>{groupSizesLabel}</h5>
      </CardHeader>
      <CardBody>
        <SizesDisplay />
      </CardBody>
    </Card>
  );
};

const stateToProps = state => ({
  presentStudentCount: presentStudentCountSelector(state),
  targetingMode: targetingModeSelector(state),
  targetGroupSize: targetGroupSizeSelector(state),
  targetGroupCount: targetGroupCountSelector(state),
  groupCount: groupCountSelector(state),
  minGroupSize: minGroupSizeSelector(state),
  maxGroupSize: maxGroupSizeSelector(state),
});

export default connect(stateToProps)(SizesPreview);
