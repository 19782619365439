import React from 'react';
import {connect} from 'react-redux';
import {ConfirmationDialog} from '..';
import {Modal, ModalBody, Form, FormGroup, FormFeedback, Label, Input, ModalFooter, Button} from 'reactstrap';
import {getNameValidation, getStudentsValidation} from './validations';
import {onlyAlphaSpacesAndNewlines, onlyAlphaSpacesPunctuationAndNumbers} from '../../utils/sanitizers';
import {openClassNameSelector, isClassModalEditModeSelector, studentListSelector} from '../../redux/selectors';
import {setIsClassModalVisible} from '../../redux/actions/class';
import {createClass, updateClass, deleteClass} from '../../redux/actions/classList';
import {MAX_CLASS_NAME_LENGTH, MAX_STUDENT_NAME_LENGTH} from '../../utils/constants';

class CreateView extends React.Component {
  constructor(props) {
    super(props);
    if (props.isEditMode) {
      this.state = {
        name: props.name,
        students: props.studentList.map(student => student.id).join('\n'),
        isDeleteModalOpen: false,
      };
    }
    else {
      this.state = {
        name: '',
        students: '',
        isDeleteModalOpen: false,
      };
    }
  }

  handleUpdateName = (event) => {
    const sanitizedName = onlyAlphaSpacesPunctuationAndNumbers(event.target.value);

    this.setState({name: sanitizedName});
  };

  handleUpdateStudents = (event) => {
    const sanitizedStudents = onlyAlphaSpacesAndNewlines(event.target.value).
      split('\n').
      map(name => name.substring(0, MAX_STUDENT_NAME_LENGTH)).
      join('\n');

    this.setState({students: sanitizedStudents});
  };

  handleDone = (event) => {
    event.preventDefault();
    const {name, students} = this.state;
    const {isEditMode, createClass, updateClass} = this.props;
    const studentList = students.split('\n').filter(Boolean).map(name => ({
      id: name,
      isPresent: true,
    }));

    if (isEditMode) {
      updateClass({name, studentList});
    }
    else {
      createClass({name, studentList});
    }
    this.handleClose();
  };

  handleDelete = () => {
    const {deleteClass} = this.props;

    deleteClass();
    this.handleClose();
  };

  openDeleteModal = () => {
    this.setState({isDeleteModalOpen: true});
  };

  closeDeleteModal = () => {
    this.setState({isDeleteModalOpen: false});
  };

  handleClose = () => {
    this.props.setIsClassModalVisible(false);
  };

  render() {
    const {name, students, isDeleteModalOpen} = this.state;
    const {isEditMode} = this.props;

    const nameValidation = getNameValidation(name);
    const nameInput =
      <FormGroup>
        <Label for='ClassModalName'>Name</Label>
        <Input
          type='text'
          id='ClassModalName'
          value={name}
          maxLength={MAX_CLASS_NAME_LENGTH}
          onChange={this.handleUpdateName}
          invalid={Boolean(nameValidation)}
        />
        <FormFeedback>{nameValidation}</FormFeedback>
      </FormGroup>

    ;

    const studentsValidation = getStudentsValidation(students);
    const studentsInput =
      <FormGroup>
        <Label for='ClassModalStudents'>Students (one per line)</Label>
        <Input
          type='textarea'
          id='ClassModalStudents'
          value={students}
          onChange={this.handleUpdateStudents}
          invalid={Boolean(studentsValidation)}
        />
        <FormFeedback>{studentsValidation}</FormFeedback>
      </FormGroup>

    ;

    const deleteButton = isEditMode ?
      <Button color='danger' onClick={this.openDeleteModal}>
        Delete
      </Button> :
      <div />;

    const hasValidationErrors = Boolean(nameValidation || studentsValidation);
    const doneAndCancelButtons =
      <div>
        <Button
          color='primary'
          onClick={this.handleDone}
          id='ClassModalDone'
          disabled={hasValidationErrors}
        >
          Done
        </Button>
        <Button color='light' onClick={this.handleClose}>
          Cancel
        </Button>
      </div>

    ;

    return (
      <Modal isOpen centered toggle={this.handleClose}>
        <Form>
          <ModalBody>
            {nameInput}
            {studentsInput}
          </ModalBody>
          <ModalFooter>
            {deleteButton}
            {doneAndCancelButtons}
          </ModalFooter>
        </Form>
        <ConfirmationDialog
          isOpen={isDeleteModalOpen}
          onConfirm={this.handleDelete}
          handleClose={this.closeDeleteModal}
          headerText='Are you sure you want to delete this class?'
        />
      </Modal>
    );
  }
}

const stateToProps = state => ({
  isEditMode: isClassModalEditModeSelector(state),
  name: openClassNameSelector(state),
  studentList: studentListSelector(state),
});

const dispatchToProps = {
  createClass,
  deleteClass,
  updateClass,
  setIsClassModalVisible,
};

export default connect(stateToProps, dispatchToProps)(CreateView);
