import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner} from 'reactstrap';
import {openAssignmentById} from '../../redux/actions/assignment';
import {isOpenClassLoadingSelector, sortedAssignmentListSelector} from '../../redux/selectors';

export function AssignmentsDropdown({
  isOpenClassLoading,
  assignmentList,
  openAssignmentById,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => setIsOpen(!isOpen);

  const handleOpenAssignment = event => {
    const assignmentId = event.target.id;

    setIsOpen(false);
    openAssignmentById(assignmentId);
  };

  const menuItems = isOpenClassLoading ?
    <DropdownItem><Spinner /></DropdownItem> :
    assignmentList.map(({id, name}) =>
      <DropdownItem key={id} id={id} onClick={handleOpenAssignment}>{name}</DropdownItem>
    );

  return (
    <Dropdown isOpen={isOpen} toggle={toggleIsOpen}>
      <DropdownToggle color='light' id='AssignmentToggler'>
        <h5 className='bold'>Assignments</h5>
      </DropdownToggle>
      <DropdownMenu>
        {menuItems}
      </DropdownMenu>
    </Dropdown>
  );
}

const stateToProps = state => ({
  isOpenClassLoading: isOpenClassLoadingSelector(state),
  assignmentList: sortedAssignmentListSelector(state),
});

const dispatchToProps = {
  openAssignmentById,
};

export default connect(stateToProps, dispatchToProps)(AssignmentsDropdown);
