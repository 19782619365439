import React from 'react';
import {Container, Jumbotron, Button} from 'reactstrap';

export function UnsupportedPage() {
  const handleClick = () => {
    window.location.href = 'https://jakearchibald.github.io/isserviceworkerready/';
  };

  return (
    <Container id='UnsupportedPage' className='page'>
      <Jumbotron>
        <h1 className='display-3'>Aw, blast.</h1>
        <h4>This website requires service-workers. Your browser doesn&apos;t support them.</h4>
        <hr />
        <Button color='primary' size='lg' active onClick={handleClick}>
          See which browsers do.
        </Button>
      </Jumbotron>
    </Container>
  );
}

export default UnsupportedPage;
