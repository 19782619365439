import React from 'react';
import {connect} from 'react-redux';
import {CustomInput, Card, CardHeader, CardBody, CardFooter, Button} from 'reactstrap';
import {RadioButtonGroup} from '../../../components';
import {TARGETING_MODE} from '../../../utils/constants';
import {
  setTargetingMode,
  setTargetGroupSize,
  setTargetGroupCount,
  generateGroups,
  setIsAssignmentModalVisible,
} from '../../../redux/actions/assignment';
import {
  presentStudentCountSelector,
  minGroupSizeSelector,
  maxGroupSizeSelector,
  targetingModeSelector,
  targetGroupSizeSelector,
  targetGroupCountSelector,
  groupCountSelector,
} from '../../../redux/selectors';

export const SizeControls = ({
  presentStudentCount,
  targetingMode,
  targetGroupSize,
  targetGroupCount,
  setTargetingMode,
  setTargetGroupSize,
  setTargetGroupCount,
  generateGroups,
  setIsAssignmentModalVisible,
}) => {
  const handleGenerate = () => {
    generateGroups();
    setIsAssignmentModalVisible(true);
  };

  const handleSliderChange = event => {
    const targetValue = parseInt(event.target.value, 10);

    if (targetingMode === TARGETING_MODE.STUDENTS_PER_GROUP && targetValue !== targetGroupSize) {
      setTargetGroupSize(targetValue);
    }
    else if (targetingMode === TARGETING_MODE.GROUP_COUNT && targetValue !== targetGroupCount) {
      setTargetGroupCount(targetValue);
    }
  };

  let sliderValue = targetGroupSize;

  if (targetingMode === TARGETING_MODE.GROUP_COUNT) {
    sliderValue = targetGroupCount;
  }
  const minSliderValue = 2;
  const maxSliderValue = presentStudentCount;

  return (
    <Card id='SizeControls'>
      <CardHeader>
        <h3>{sliderValue}</h3>
        <RadioButtonGroup
          selectedOption={targetingMode}
          options={[
            'Students per Group',
            'Groups Total',
          ]}
          onChange={setTargetingMode}
        />
      </CardHeader>
      <CardBody>
        <CustomInput
          id='GroupSizeSlider'
          type='range'
          value={sliderValue}
          min={minSliderValue}
          max={maxSliderValue}
          step={1}
          onChange={handleSliderChange}
        />
      </CardBody>
      <CardFooter>
        <Button color='primary' onClick={handleGenerate}>Generate</Button>
      </CardFooter>
    </Card>
  );
};

const stateToProps = state => ({
  presentStudentCount: presentStudentCountSelector(state),
  targetingMode: targetingModeSelector(state),
  targetGroupSize: targetGroupSizeSelector(state),
  targetGroupCount: targetGroupCountSelector(state),
  groupCount: groupCountSelector(state),
  minGroupSize: minGroupSizeSelector(state),
  maxGroupSize: maxGroupSizeSelector(state),
});

const dispatchToProps = {
  setTargetingMode,
  setTargetGroupSize,
  setTargetGroupCount,
  generateGroups,
  setIsAssignmentModalVisible,
};

export default connect(stateToProps, dispatchToProps)(SizeControls);
