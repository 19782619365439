
export const MAX_CLASS_NAME_LENGTH = 36;
export const MAX_STUDENT_NAME_LENGTH = 36;
export const MAX_STUDENTS_PER_CLASS = 110;
export const NORMAL = 'NORMAL';
export const LOADING_CLASS = 'LOADING_CLASS';
export const ERROR = 'ERROR';
export const TARGETING_MODE = {
  STUDENTS_PER_GROUP: 0,
  GROUP_COUNT: 1,
};
