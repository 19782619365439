import {connectRouter} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import {createModelReducer} from 'redux-reducer-patterns';
import model from './model';

export const history = createBrowserHistory();

const routerReducer = connectRouter(history);
const modelReducer = createModelReducer(model);

export default ({router, ...modelState} = {}, action) => ({
  router: routerReducer(router, action),
  ...modelReducer(modelState, action),
});
